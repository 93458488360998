<template>
  <b-tabs v-model="activeTab" pills card>
    <b-tab :title="$t('page_meta') || 'page_meta'">
      <AppInput
        id="main_keywords"
        v-model="meta_focus_keyphrase_ar"
        type="text"
        :label="$t('main_keywords') || 'main_keywords'"
        class="mb-2"
        @input="changeFormData('meta_focus_keyphrase_ar', $event)"
      />

      <hr />

      <article>
        <div class="card-body px-0">
          <h4 v-if="app_name" class="mb-2">
            {{ $t("preview") || "preview" }}
          </h4>
          <article v-if="app_name" class="border p-2 mb-2 rounded">
            <h5 class="text-success mb-0">
              {{ meta_title_ar }} {{ predeifnedSeoTitle || "" }}
            </h5>
            <p class="btn btn-link mb-1 p-0">
              {{ app_url }}
            </p>
            <p class="text-dark mb-0">
              {{ meta_description_ar }}
            </p>
          </article>

          <AppInput
            id="seo-title"
            v-model="meta_title_ar"
            type="text"
            :label="$t('seo_title') || 'seo Title'"
            @input="changeFormData('meta_title_ar', $event)"
          />

          <AppTextarea
            id="seo-description"
            v-model="meta_description_ar"
            data-test="meta_description_ar"
            rows="3"
            max-rows="8"
            :label="$t('meta_description_ar') || 'meta Description'"
            :placeholder="$t('meta_description_ar') || 'meta Description'"
            @input="changeFormData('meta_description_ar', $event)"
          />
        </div>
      </article>
    </b-tab>
    <b-tab :title="$t('social_seo') || 'Social_seo'">
      <!-- facebook seo -->
      <FacebookSeo
        v-if="activeTab === 1"
        :meta="meta"
        @change="changeFormData"
      />
      <!-- twitter seo -->
      <TwitterSeo
        v-if="activeTab === 1"
        :meta="meta"
        @change="changeFormData"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTab, BTabs } from "bootstrap-vue";
import { AppButton, AppInput, AppTextarea } from "@/components/form/index";

import FacebookSeo from "./FacebookSeo.vue";
import TwitterSeo from "./TwitterSeo.vue";

export default {
  components: {
    FacebookSeo,
    TwitterSeo,
    BTab,
    BTabs,
    AppInput,
    AppTextarea,
  },
  props: {
    meta: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      app_url: process.env.VUE_APP_BASE_URL || "",
      app_name:
        this.$t(process.env.VUE_APP_NAME) || process.env.VUE_APP_NAME || "",
      predeifnedSeoTitle: this.app_name && ` - ${this.app_name}`,
      meta_title_ar: "",
      meta_description_ar: "",
      meta_focus_keyphrase_ar: "",
      activeTab: 0,
    };
  },
  mounted() {
    // this.meta_title_ar = this.meta?.seo?.meta_title_ar || "";
    // this.meta_description_ar = this.meta?.seo?.meta_description_ar || "";
    // this.meta_focus_keyphrase_ar =
    //   this.meta?.seo?.meta_focus_keyphrase_ar || "";
  },
  watch: {
    meta: {
      handler(val) {
        if (!val) return;
        this.meta_title_ar = val?.meta_title_ar;
        this.meta_description_ar = val?.meta_description_ar;
        this.meta_focus_keyphrase_ar = val?.meta_focus_keyphrase_ar;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  methods: {
    changeFormData(key, value) {
      this.$emit("change", key, value);
    },
  },
};
</script>
