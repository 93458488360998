<template>
  <b-card class="border shadow-sm">
    <AppButton variant="primary" :label="$t('facebook') || 'Facebook'">
    </AppButton>

    <div class="card-body">
      <AppInput
        id="facebook-title"
        v-model="facebook_title_ar"
        type="text"
        :label="$t('facebook_title') || 'Facebook Title'"
        @input="changeFormData('facebook_title_ar', $event)"
      />

      <AppTextarea
        id="facebook-description"
        v-model="facebook_description_ar"
        data-test="job_description"
        rows="3"
        max-rows="8"
        :label="$t('facebook_description') || 'facebook Description'"
        :placeholder="$t('facebook_description') || 'facebook Description'"
        @input="changeFormData('facebook_description_ar', $event)"
      />
      <fieldset class="form-group">
        <legend for="facebook-image" class="col-form-label pt-0">
          {{ $t("facebook_image") || "Facebook Image" }}
        </legend>
        <img
          v-if="facebook_image"
          width="100%"
          data-test="image"
          height="200"
          class="object-fit-contain mb-2"
          :src="facebook_image.path"
          :alt="facebook_image.alt_ar"
        />
        <AppButton
          variant="outline-secondary"
          data-test="choose_image_btn"
          class="w-100"
          type="button"
          :label="
            facebook_image
              ? $t('change_image') || 'Change Image'
              : $t('choose_image') || 'Choose Image'
          "
          @click="openGallaryModal"
        />
      </fieldset>
    </div>
    <GalleryModal id="facebook-gallary-modal" @select="selectImageHandler" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import _ from "lodash";
import { AppButton, AppInput, AppTextarea } from "@/components/form/index";
import GalleryModal from "@/components/UI/GalleryModal.vue";

export default {
  name: "FacebookSeo",
  components: {
    BCard,
    AppButton,
    AppTextarea,
    AppInput,
    GalleryModal,
  },
  props: {
    meta: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      facebook_title_ar: "",
      facebook_description_ar: "",
      facebook_image: null,
    };
  },
  watch: {
    meta: {
      handler(val) {
        if (!val) return;
        this.facebook_title_ar = val.facebook_title_ar;
        this.facebook_description_ar = val.facebook_description_ar;
        this.facebook_image = val.facebook_image;
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  mounted() {},
  methods: {
    selectImageHandler(image) {
      console.log(
        "🚀 ~ file: FacebookSeo.vue ~ line 105 ~ selectImageHandler ~ image",
        image
      );
      this.facebook_image = image;
      this.changeFormData("facebook_image", image || null);
    },
    openGallaryModal() {
      this.$bvModal.show("facebook-gallary-modal");
    },
    changeFormData(key, value) {
      this.$emit("change", key, value);
    },
  },
};
</script>
