<template>
  <b-card class="border shadow-sm">
    <AppButton variant="primary">
      {{ $t("twitter") || "Twitter" }}
    </AppButton>

    <div class="card-body">
      <AppInput
        id="twitter-title"
        v-model="twitter_title_ar"
        type="text"
        :label="$t('twitter_title') || 'twitter Title'"
        @input="changeFormData('twitter_title_ar', $event)"
      />
      <AppTextarea
        id="twitter-description"
        v-model="twitter_description_ar"
        data-test="job_description"
        rows="3"
        max-rows="8"
        :label="$t('twitter_description') || 'twitter Description'"
        :placeholder="$t('twitter_description') || 'twitter Description'"
        @input="changeFormData('twitter_description_ar', $event)"
      />

      <fieldset class="form-group mt-2">
        <legend class="col-form-label pt-0">
          {{ $t("twitter_image") || "twitter Image" }}
        </legend>
        <img
          v-if="twitter_image"
          width="100%"
          data-test="image"
          height="200"
          class="object-fit-contain mb-2"
          :src="twitter_image.path"
          :alt="twitter_image.alt_ar"
        />
        <AppButton
          variant="outline-secondary"
          data-test="choose_image_btn"
          class="w-100"
          type="button"
          :label="
            twitter_image
              ? $t('change_image') || 'Change Image'
              : $t('choose_image') || 'Choose Image'
          "
          @click="openGallaryModal"
        />
      </fieldset>
    </div>
    <GalleryModal id="twitter-gallary-modal" @select="selectImageHandler" />
  </b-card>
</template>
<script>
import { BCard } from "bootstrap-vue";
import { AppButton, AppInput, AppTextarea } from "@/components/form/index";
import GalleryModal from "@/components/UI/GalleryModal.vue";

export default {
  name: "TwitterSeo",
  components: {
    BCard,
    AppButton,
    AppInput,
    AppTextarea,
    GalleryModal,
  },
  props: {
    meta: {
      required: false,
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      twitter_description_ar: null,
      twitter_title_ar: null,
      twitter_image: null,
    };
  },
  computed: {
    isRTL() {
      return window.localStorage.getItem("language") === "ar";
    },
  },
  watch: {
    meta: {
      handler(val) {
        if (!val) return;
        this.twitter_title_ar = val.twitter_title_ar;
        this.twitter_description_ar = val.twitter_description_ar;
        this.twitter_image = val.twitter_image;
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {},
  methods: {
    selectImageHandler(image) {
      this.twitter_image = image;
      this.changeFormData("twitter_image", image || null);
    },
    openGallaryModal() {
      this.$bvModal.show("twitter-gallary-modal");
    },
    changeFormData(key, value) {
      this.$emit("change", key, value);
    },
  },
};
</script>
